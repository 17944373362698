import type { LocizeBackendOptions } from 'i18next-locize-backend';

const isSSR = typeof window === 'undefined';

const nodeEnv = isSSR ? process.env.NODE_ENV : window.ENV.NODE_ENV;

const isProd = nodeEnv === 'production';
const isDev = nodeEnv === 'development';

const environment = isSSR ? process.env.ENVIRONMENT : window.ENV.ENVIRONMENT;

const apiKey = isSSR ? process.env.LOCIZE_API_KEY : window.ENV.LOCIZE_API_KEY;

export const locizeOptions: LocizeBackendOptions = {
  projectId: '262e9726-f78e-41d2-a6f2-b5b326d182a2',
  apiKey: isProd ? undefined : apiKey,
  version: environment === 'production' ? 'production' : 'latest',
};

export const i18nextOptions = {
  supportedLngs: ['en'],
  fallbackLng: 'en',
  react: { useSuspense: false },
  debug: !isSSR && isDev,
};
